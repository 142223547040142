import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import "./style.css";

import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";

const Media = () => {
  const data = useStaticQuery(graphql`
    query Mediaquery {
      markdownRemark(fileAbsolutePath: { regex: "/media.md/" }) {
        frontmatter {
          img_thb1a
          img_thb2a
          img_thb1b
          img_thb2b
        }
      }
    }
  `);

  return (
    <div id="media">
      <h2 className="title">
        Media Report | <span className="subtitle"> Lovepreneur</span>
      </h2>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Hidden mdDown>
            <img
              src={data.markdownRemark.frontmatter.img_thb1a}
              alt=""
              width="100%"
            />
          </Hidden>
          <Hidden mdUp>
            <img
              src={data.markdownRemark.frontmatter.img_thb1b}
              alt=""
              width="100%"
            />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden mdDown>
            <img
              src={data.markdownRemark.frontmatter.img_thb2a}
              alt=""
              width="100%"
            />
          </Hidden>
          <Hidden mdUp>
            <img
              src={data.markdownRemark.frontmatter.img_thb2b}
              alt=""
              width="100%"
            />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
export default Media;
