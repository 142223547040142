import * as React from "react";

import "./style.css";
import "../header/style.css";

import { graphql, useStaticQuery } from "gatsby";

import Carousel from "nuka-carousel";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const Hero = (props) => {
  const data = useStaticQuery(graphql`
    query Heroquery {
      markdownRemark(fileAbsolutePath: { regex: "/hero.md/" }) {
        frontmatter {
          slider {
            img_item
          }
          hero_logo
          title
          year
          author
          desc
          star
          writer
          dir
          prod
          duration
        }
      }
    }
  `);

  const [idx, setIdx] = React.useState();
  const [cidx, setCidx] = React.useState();

  return (
    <div id="hero">
      <div className="con1">
        <Carousel
          className="carousel"
          animation="fade"
          withoutControls
          autoplay
          wrapAround
          slideIndex={idx}
          renderAnnounceSlideMessage={({ currentSlide }) => {
            setCidx(currentSlide);
          }}
          autoplayInterval={3000}
          speed={3000}
        >
          {data.markdownRemark.frontmatter.slider.map((item, i) => (
            <img
              key={i}
              src={item.img_item}
              alt={i}
              draggable={false}
              width="100%"
              height="100%"
            />
          ))}
        </Carousel>
      </div>
      <div className="con2">
        <div className="ovf">
          <div className="ovf-content">
            <img
              src={data.markdownRemark.frontmatter.hero_logo}
              alt=""
              height="110px"
            />
            <div className="mxw">
              <h2>{data.markdownRemark.frontmatter.title}</h2>
              <h4 className="desc">
                {data.markdownRemark.frontmatter.year} |{" "}
                {data.markdownRemark.frontmatter.author}
              </h4>
              <h4>{data.markdownRemark.frontmatter.desc}</h4>
              <br />
              <h4>
                <span>Starring : </span> {data.markdownRemark.frontmatter.star}
              </h4>
              <h4>
                <span>Writer : </span> {data.markdownRemark.frontmatter.writer}
              </h4>
              <h4>
                <span>Director : </span> {data.markdownRemark.frontmatter.dir}
              </h4>
              <h4>
                <span>Executive Producer : </span>{" "}
                {data.markdownRemark.frontmatter.prod}
              </h4>
              {/* <h4>
                <span>Durasi : </span> {data.markdownRemark.frontmatter.duration}
              </h4> */}
            </div>

            <Grid container alignItems="center" spacing={2} mt="40px">
              <Grid item xs>
                <Button
                  href={
                    props.refferal
                      ? `https://nobar.lovepreneur.co.id/daftar/ref=${
                          props.refferal ? props.refferal : ""
                        }&produk_id=${props.prod ? props.prod : ""}`
                      : "https://nobar.lovepreneur.co.id/daftar/"
                  }
                  className="cta"
                >
                  BELI TIKET
                </Button>
              </Grid>
              <Grid item>
                <div className="dtsc">
                  {data.markdownRemark.frontmatter.slider.map((item, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => setIdx(i)}
                        className="dot"
                        style={{
                          backgroundColor: cidx === i ? "#FFF" : "#C4C4C4",
                        }}
                      />
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
