import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import "./style.css";

import Grid from "@mui/material/Grid";

const Benefit = () => {
  const data = useStaticQuery(graphql`
    query Benefitquery {
      markdownRemark(fileAbsolutePath: { regex: "/benefit.md/" }) {
        frontmatter {
          img_thb1
          title1
          desc1
          img_thb2
          title2
          desc2
          img_thb3
          title3
          desc3
        }
      }
    }
  `);

  return (
    <div id="benefit">
      <h2 className="title">
        Benefits | <span className="subtitle"> Lovepreneur</span>
      </h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <img
            src={data.markdownRemark.frontmatter.img_thb1}
            alt={data.markdownRemark.frontmatter.title1}
            width="100%"
          />
          <h3 className="name">{data.markdownRemark.frontmatter.title1}</h3>
          <p className="desc">{data.markdownRemark.frontmatter.desc1}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={data.markdownRemark.frontmatter.img_thb2}
            alt={data.markdownRemark.frontmatter.title2}
            width="100%"
          />
          <h3 className="name">{data.markdownRemark.frontmatter.title2}</h3>
          <p className="desc">{data.markdownRemark.frontmatter.desc2}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            src={data.markdownRemark.frontmatter.img_thb3}
            alt={data.markdownRemark.frontmatter.title3}
            width="100%"
          />
          <h3 className="name">{data.markdownRemark.frontmatter.title3}</h3>
          <p className="desc">{data.markdownRemark.frontmatter.desc3}</p>
        </Grid>
      </Grid>
    </div>
  );
};
export default Benefit;
