import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Hidden from "@mui/material/Hidden";

import "./style.css";

import Header from "../components/header";
import Hero from "../components/hero";
import Divider from "../components/hero/divider";
// import Video from "../components/video";
import Media from "../components/media";
import Film from "../components/film";
import Benefit from "../components/benefit";
import { useQueryParam } from "gatsby-query-params";
import { isBrowser } from "../components/utils";
import Testimoni from "../components/testimoni";

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );

  let urlref = useQueryParam("ref", "");
  let urlprodukid = useQueryParam("produk_id", "");

  const [refLocal, setReflocal] = React.useState("");
  const [prodLocal, setProdlocal] = React.useState("");

  if (isBrowser) {
    if (urlref !== null) {
      localStorage.setItem("ref", JSON.stringify(urlref));
      localStorage.setItem("produk_id", JSON.stringify(urlprodukid));

      // setReflocal(urlref);
      // setProdlocal(urlprodukid);
    }
  }

  React.useEffect(() => {
    setReflocal(localStorage.getItem("ref")?.replace(/['"]+/g, ""));
    setProdlocal(localStorage.getItem("produk_id")?.replace(/['"]+/g, ""));
  });

  return (
    <div id="home">
      <Helmet title={site.siteMetadata?.title} />
      <div className="content">
        <Header refferal={refLocal} prod={prodLocal} />
        <Hero refferal={refLocal} prod={prodLocal} />
        <div className="content-con">
          <Hidden mdDown>
            <Divider />
          </Hidden>
          {/* <Video refferal={refLocal} prod={prodLocal} /> */}
          <Benefit refferal={refLocal} prod={prodLocal} />
          <Media refferal={refLocal} prod={prodLocal} />
          <Testimoni refferal={refLocal} prod={prodLocal} />
          <Film refferal={refLocal} prod={prodLocal} />
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
