import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import "./style.css";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";

const Film = (props) => {
  const data = useStaticQuery(graphql`
    query Filmquery {
      markdownRemark(fileAbsolutePath: { regex: "/film.md/" }) {
        frontmatter {
          img_thb1
          img_thb2
          img_title
        }
      }
    }
  `);

  return (
    <div id="film">
      <h2 className="title">
        Film | <span className="subtitle"> Lovepreneur</span>
      </h2>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={5}>
          <a
            href="https://youtu.be/DA1lf9iA4rU"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={data.markdownRemark.frontmatter.img_thb1}
              alt=""
              width="100%"
            />
          </a>
        </Grid>
        <Grid item xs={12} md={5}>
          <Hidden mdDown>
            <a
              href={
                props.refferal
                  ? `https://nobar.lovepreneur.co.id/daftar/ref=${
                      props.refferal ? props.refferal : ""
                    }&produk_id=${props.prod ? props.prod : ""}`
                  : "https://nobar.lovepreneur.co.id/daftar/"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={data.markdownRemark.frontmatter.img_thb2}
                alt=""
                width="100%"
              />
            </a>
          </Hidden>
          <Hidden mdUp>
            <h3 className="img-title">{data.markdownRemark.frontmatter.img_title}</h3>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className="cta"
            mx="auto"
            href={
              props.refferal
                ? `https://nobar.lovepreneur.co.id/daftar/ref=${
                    props.refferal ? props.refferal : ""
                  }&produk_id=${props.prod ? props.prod : ""}`
                : "https://nobar.lovepreneur.co.id/daftar/"
            }
          >
            BELI TIKET
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default Film;
