import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import "./style.css";

import Grid from "@mui/material/Grid";

const Testimoni = () => {
  const data = useStaticQuery(graphql`
    query Testimoniquery {
      markdownRemark(fileAbsolutePath: { regex: "/testimoni.md/" }) {
        frontmatter {
          img_1
          img_2
          img_3
          img_4
        }
      }
    }
  `);

  return (
    <div id="testimoni">
      <h2 className="title">
        Testimonial | <span className="subtitle"> Lovepreneur</span>
      </h2>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={data.markdownRemark.frontmatter.img_1}
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={data.markdownRemark.frontmatter.img_2}
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={data.markdownRemark.frontmatter.img_3}
            alt=""
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img
            src={data.markdownRemark.frontmatter.img_4}
            alt=""
            width="100%"
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default Testimoni;
