import * as React from "react";

import "./style.css";

const Divider = () => {
  return (
    <div
      style={{
        height: "16px",
        width: "100%",
        zIndex: 200,
        background:
          "linear-gradient(90deg, rgba(250, 35, 152, 0.75) 0%, #001C2C 100%)",
      }}
    />
  );
};
export default Divider;
